<template>
  <div id="wrapDrawer" class="container">
    <a-drawer
      ref="drawer"
      :wrap-class-name="wrapClassName"
      :placement="placement"
      :width="size | sizeList"
      :closable="closable"
      :visible="visible"
      :z-index="zIndex"
      :get-container="getContainer"
      :mask-closable="isMask"
      :body-style="bodyStyle"
      :destroy-on-close="destroyOnClose"
      @after-visible-change="afterVisibleChange"
      @close="onClose"
    >
      <!-- 标题区域 -->
      <template slot="title">
        <span>{{ title }}</span>
        <slot name="title" />
      </template>
      <!-- 内容区域 -->
      <div class="drawer-content">
        <slot />
      </div>
      <!-- 底部按钮 -->
      <div class="button" :style="{ display: displayBtn ? 'none' : 'block', borderTop: `1px solid ${ displayBtn ? '#fff' : '#e8e9ec'}`}">
        <slot name="preFooter" />
        <a-button v-if="showCancel" type="default" @click="onClose">
          {{ cancelText }}
        </a-button>
        <slot name="cenFooter" />
        <a-button v-if="showConfirm" type="primary" @click="onSubmit">
          {{ confirmText }}
        </a-button>
        <slot name="sufFooter" />
      </div>
    </a-drawer>
  </div>
</template>

<script>
const sizeList = {
  small: '500px',
  normal: '634px',
  large: '50%'
}
export default {
  name: 'Drawer',
  filters: {
    sizeList(val) {
      return sizeList[val]
    }
  },
  model: {
    prop: 'visible',
    event: 'getVisible'
  },
  props: {
    // 抽屉框显隐
    visible: {
      type: Boolean,
      default: false
    },
    // 抽屉框标题
    title: {
      type: [String, Object],
      default: () => {}
    },
    // 外层容器的类名
    wrapClassName: {
      type: String,
      default: ''
    },
    // 设置 Drawer 内容部分的样式
    bodyStyle: {
      type: Object,
      default: () => {}
    },
    // 抽屉位置
    placement: {
      type: String,
      default: 'right'
    },
    // 抽屉框尺寸
    size: {
      type: String,
      default: ''
    },
    // 是否显示右上角关闭按钮
    closable: {
      type: Boolean,
      default: true
    },
    // 点击蒙层是否关闭
    isMask: {
      type: Boolean,
      default: false
    },
    // 是否显示按钮
    displayBtn: {
      type: Boolean,
      default: false
    },
    // 是否显示取消按钮
    showCancel: {
      type: Boolean,
      default: true
    },
    // 是否显示确认按钮
    showConfirm: {
      type: Boolean,
      default: true
    },
    // 取消按钮文案
    cancelText: {
      type: String,
      default: '取消'
    },
    // 确认按钮文案
    confirmText: {
      type: String,
      default: '确认'
    },
    // 关闭时销毁 Drawer 里的子元素
    destroyOnClose: {
      type: Boolean,
      default: true
    },
    zIndex: {
      type: [Number, String],
      default: 2022
    },
    getContainer: {
      type: [Function, Boolean, String],
      default: 'body'
    }
  },
  data() {
    return {
      value: undefined,
      btn: ''
    }
  },
  computed: {},
  watch: {
    visible: {
      handler: function(val) {
        this.value = val
      },
      immediate: true
    },
    size: {
      handler: function(val) {
        if (val === 'large') {
          this.btn = '100%'
        }
        this.btn = sizeList[val]
      },
      immediate: true
    }
  },
  methods: {
    // 确认功能
    onSubmit() {
      this.$emit('onSubmit')
    },
    // 关闭功能
    onClose() {
      this.$emit('close')
    },
    afterVisibleChange() {
      this.$emit('afterVisibleChange')
    }

  }
}
</script>
<style scoped lang="less">
/deep/.ant-drawer-header{
  width: 100%;
  height: 54px;
  line-height: 54px;
  position: absolute;
  top: 0;
  z-index: 1;
  .ant-drawer-title{
    color: @sc-grey-100;
    font-weight: normal;
  }
  .ant-drawer-close{
    font-size: 14px;
    color: @sc-grey-100;
    position: absolute;
  }
}
/deep/.ant-drawer-body{
  position: relative;
  top: 54px;
  padding: 0 !important;
  height: calc(100% - 56px);
  .ant-input{
    padding: 4px 12px;
  }
  .ant-select-selection__rendered{
    margin-left: 12px;
  }
  .ant-select-arrow{
    right: 12px
  }
  .container .select_value:nth-child(n+2){
    margin-left: 14px;
  }
  .ant-form{
    padding-bottom: 56px;
    .ant-form-item{
      margin-bottom: 24px;
    }
  }
}
.drawer-content {
  height: calc(100% - 56px);
  overflow: auto;
  padding: 20px 24px 0;
  &::-webkit-scrollbar{
  display: none;
}
}
.button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 56px;
  border-top: 1px solid @sc-greyBg-20;
  padding: 12px 24px;
  background: #fff;
  text-align: right;
  z-index: 1;
  /deep/ .ant-btn {
      padding: 5px 10px;
      margin-left: 12px;
  }
}
/deep/.ant-tree li{
  padding: 8px 0;
}
/deep/ .ant-tree-child-tree {
  li:first-child{
    padding-top: 16px;
  }
}
</style>
